import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('../pages/login/index.vue'),
      meta: {
        title: '登录',
        keywords: " 一体化服务平台, 企业服务,全流程,数字化转型,办公平台,智能财税,专注产品服务",
        description: "财税一体管理软件,数据安全,无需下载安装,多角色部门协作,多维度管理支持多行业特性"
      }
    },
    {
      path: '/disable_page',
      name: 'disable_page',
      component: () => import('../pages/home/disable_page.vue'),
      meta: {
        keywords: "办公平台,智能财税,专注产品服务",
        description: "一站式解决方案，售后无忧,安全可靠,值得信赖"
      }
    },
    {
      path: '/conceal',
      name: 'Conceal',
      component: () => import('@/pages/login/conceal.vue'),
    },
    {
      path: '/agreement',
      name: 'Agreement',
      component: () => import('@/pages/login/agreement.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('../pages/register/index.vue'),
    },
    {
      path: '/',
      name: 'Index',
      meta: {
        title: '首页',
        path: "/",
        keywords: "办公平台,智能财税,专注产品服务",
        description: "为企业搭建信息化平台，专注产品服务，多渠道接入,服务与智能化融合一体"
      },
      component: () => import('../pages/index/index.vue'),
      children: [
        {
          path: '/',
          name: 'Home',
          meta: {
            title: '首页', path: "/home", show: false,
            keywords: "办公平台,智能财税,专注产品服务",
            description: "全流程线上化，帮助企业高效办公，赋能企业数字化转型"
          },
          component: () => import('../pages/home/index.vue'),
        }, {
          path: '/home',
          name: 'Home',
          meta: {
            title: '首页', path: "/home", show: false,
            keywords: "办公平台,智能财税,专注产品服务",
            description: "企业服务提供商,为企业及公共组织提供数字化、智能化服务,推动产业转型升级用创想与技术推动商业和社会进步。"
          },
          component: () => import('../pages/home/index.vue'),
        },
        {
          path: '/news',
          name: 'News',
          meta: { title: '新闻', path: "/news", show: true },
          component: () => import('../pages/news/index.vue'),
        },
        {
          path: '/news_list',
          name: 'News_List',
          meta: { title: '新闻列表', path: "/news_list", show: true },
          component: () => import('../pages/news/news_list.vue'),
        },
        {
          path: '/news_message',
          name: 'News Message',
          meta: { title: '正文', path: "/news_message", show: true, fatherPath: "/news", fatherName: "新闻" },
          component: () => import('../pages/news/news_message.vue'),
        },
        {
          path: '/role',
          name: 'Role',
          meta: { title: '角色管理', path: "/role", show: true },
          component: () => import('../pages/organization/role.vue'),
        },
        {
          path: '/organization',
          name: 'Organization',
          meta: { title: '员工与部门管理', path: "/organization", show: true },
          component: () => import('../pages/organization/organization.vue'),
        },
        {
          path: '/organization',
          name: 'Organization',
          meta: { title: '员工与部门管理', path: "/organization", show: true },
          component: () => import('../pages/organization/organization.vue'),
        },
        {
          path: '/app',
          name: 'App',
          meta: { title: '我的应用', path: "/app", show: true },
          component: () => import('../pages/app/index.vue'),
        },
        {
          path: '/app_shop',
          name: 'App Shop',
          meta: { title: '应用市场', path: "/app_shop", show: true },
          component: () => import('../pages/app_shop/index.vue')
        },
        {
          path: '/app_details',
          name: 'App Details',
          meta: { title: '应用详情', path: "/app_details", show: true, fatherPath: "/app_shop", fatherName: "应用市场" },
          component: () => import('../pages/app_shop/details.vue'),
        },
        {
          path: '/app_shop_more',
          name: 'App Shop More',
          meta: { title: '应用市场', path: "/app_shop_more", show: true },
          component: () => import('../pages/app_shop/more.vue'),
        },
        {
          path: '/app_shop_buy',
          name: 'App Buy',
          meta: { title: '应用市场', path: "/app_shop_buy", show: true },
          component: () => import('../pages/app_shop/buy.vue'),
        },

        {
          path: '/personal_message',
          name: 'Personal Message',
          meta: { title: '个人信息', path: "/personal_message", show: true },
          component: () => import('../pages/personal_settings/personal_message.vue'),
        },
        {
          path: '/change_password',
          name: 'Change Password',
          meta: { title: '修改密码', path: "/change_password", show: true },
          component: () => import('../pages/personal_settings/change_password.vue'),
        },



        {
          path: '/order_notes',
          name: 'Order Notes',
          meta: { title: '客户订单记录', path: "/order_notes", show: false },
          component: () => import('../pages/agent_order/order_notes.vue'),
        }, {
          path: '/customer',
          name: 'Customer',
          meta: { title: '客户列表', path: "/customer", show: true },
          component: () => import('../pages/customer/customerList.vue')
        },
        {
          path: '/settle_management',
          name: 'Settle Management',
          meta: { title: '账单记录', path: "/settle_management", show: true },
          component: () => import('../pages/settle_management/bill_record.vue')
        }, {
          path: '/settle_management/settle_accounts',
          name: 'Settle Accounts',
          meta: { title: '账单记录', path: "/settle_management", show: true },
          component: () => import('../pages/settle_management/bill/settleAccounts.vue')
        }, {
          path: '/settement_record',
          name: 'Settement Record',
          meta: { title: '结算记录', path: "/settement_record", show: true },
          component: () => import('../pages/settle_management/settement_record.vue')
        },
        {
          path: '/settle_management',
          name: 'billDetails',
          meta: { title: '账单详情', path: "/settle_management", show: true },
          component: () => import('../pages/settle_management/bill/billDetails.vue')
        },
        {
          path: '/settle_account',
          name: 'Settle Account',
          meta: { title: '结算账户', path: "/settle_account", show: true },
          component: () => import('../pages/personal_settings/settle_account.vue')
        },
        {
          path: '/order',
          name: 'Order',
          meta: { title: '订单管理', path: "/order", show: true },
          component: () => import('../pages/order/index.vue'),
        },

      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem("token");
  if (token) {
    //判断用户是否登录
    if (Object.keys(from.query).length === 0) {
      //判断路由来源是否有query，处理不是目的跳转的情况
      next();
    } else {
      let redirect = from.query.redirect; //如果来源路由有query
      let wxid = from.query.wxid;
      if (to.path === redirect) {
        //这行是解决next无限循环的问题
        // navigate(to.path, next());
        next();
      } else {
        // navigate(redirect, next({ path: redirect, wxid: wxid }));
        next({ path: redirect, wxid: wxid }); //跳转到目的路由
      }
    }
  } else {
    next();
  }
  return;
});
export default router;
