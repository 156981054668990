import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "./router";
import echarts from "echarts";
Vue.prototype.$echarts = echarts;
import './assets/theme/index.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
import { message } from '@/utils/resetMessage.js'
Vue.prototype.$message = message
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
